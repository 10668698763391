export const isDkValidDate = (date) => {
    if (typeof(date) == 'undefined') {
        return '';
    } else if (date == null) {
        return '';
    } else if (date == -1) {
        return '';
    } else if (date == 0) {
        return ''
    } else {
        const dayjs = useDayjs();
        const dayjs_obj = dayjs(date);
        const dayjs_obj_epoch = dayjs.unix(0);

        if (dayjs_obj.year() == 1969) {
            return '';
        } else if (dayjs_obj.diff(dayjs_obj_epoch,'days') == 0) {
            return '';
        } else {
            return date;
        }
    }
}
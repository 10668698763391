export const isMultiIterationJobCompleted =  (type, obj) => {
    /*
    This isMultiIterationJobCompleted composable take two parameters, type and obj, and it return true if this job is completed,
    or false otherwise.

    The value for the type parameter can either be 'message' or 'job'.
    */

    let is_finished = false;

    let job = obj['job'];
    let schedule_options = job['special_param_values']['schedule_options'];
    let num_iterations = schedule_options['num_iterations'];
    let job_status = job['status'];
    let curr_iter = job_status['curr_iter'];
    if (job_status['state'] == 'STOPISSUED') {
        is_finished = true;
    } else if (job_status['state'] == 'FINISHED') {
        is_finished = true;
    }

    return is_finished;
}
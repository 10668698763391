export const canEdit = (task) => {
    if (typeof (window) != 'undefined') {
        let isAuthenticated = localStorage.getItem('isAuthenticated');
        if (isAuthenticated == null) {
            return false;
        } else {
            let role = localStorage.getItem('role');
            if ((role != null) && (['supremo','admin'].includes(role.toLowerCase()))) {
                return true; // Allow admins and supremo to delete tasks.
            }

            let uid = localStorage.getItem('uid');
            if (task['creator'] == uid) {
                return true;
            }
            if (task.hasOwnProperty('approved_permissions')) {
                if ((task['approved_permissions'][uid]) && (task['approved_permissions'][uid]['roles'].includes('edit'))) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }    
    } else {
        return false;
    }
}

export const selectBestProxyForCopilot = async (user_id, proxy_aliases) => {
    /*
    If the tenant has multiple proxies, this method return the name of the first proxy that the given user_id has access to.
    */
    let proxy_roles_for_user_store = useProxyRolesForUserStore();
    if (proxy_roles_for_user_store.getProxyRoles == null) {
      let proxy_roles_for_user = await getProxyRolesForUser(user_id);
      proxy_roles_for_user_store.setProxyRoles(proxy_roles_for_user);
    }
    
    let all_proxy_roles_for_user = proxy_roles_for_user_store.getProxyRoles;
    let selected_proxy = "";
    for (let i = 0; i < proxy_aliases.length; i++) {
        let proxy_alias = proxy_aliases[i];
        if ((all_proxy_roles_for_user.hasOwnProperty(proxy_alias)) && (all_proxy_roles_for_user[proxy_alias].length > 0)) {
            selected_proxy = proxy_alias;
            break;
        }
    }
    if (selected_proxy == "") {
        dkAlert("Error:  You do not have any role on any proxy.");
    }
    return selected_proxy;
}
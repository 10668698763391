<template>
    <div>
        <ul class="tabs">
            <li v-for="(tab, i) in tabs" @click="setActiveTab(tab.id)" :key="i" :class="{'tab-active': tab.is_active, 'tab-inactive': !tab.is_active}" :style="tabStyle">
              <span>{{ tab.title }} <span class="unsave-icon" v-if="tab.hasUnSavedChange"><i class="fa-solid dot icon-color" role="button" aria-hidden="true"></i></span></span>  
              <span class="tab-close-icon tab-close" @click="removeTab(i, tab.id)">
                <i class="fa-light fa-times fa-2x" role="button" aria-hidden="true"></i>
              </span>
            </li>
        </ul>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
  
  const emits = defineEmits(["tab-activated"]);

  const taskStore = useTaskStore();
  const tabs = computed( () => {
    return taskStore.getCodeEditorTabs
  })


  const tabStyle = ref('')

  const removeTab = (index, id) => {
    tabs.value.splice(index, 1)
    taskStore.removeCodeEditorTabs(id)
    taskStore.removeTask(id)
    setTabWidth()

    if(taskStore.getTasks.length === 0){
      taskStore.setCodeEditorVisibility(false);
      taskStore.setChatVisible(true)
    }    
  }

  const setTabWidth = () => {
    const tabCount = tabs.value.length;
    const width = tabCount > 0 ? (100 / tabCount) + '%' : 'auto'; 
    tabStyle.value = `width: ${width}` 
  }

  const setActiveTab = (tabId) => {
    let updatedTabs = [];
    if(tabs.value.length > 0){
      updatedTabs = tabs.value.map(tab => {
        return {
          ...tab,
          is_active: tab.id === tabId
        };
      });

      const newActiveTab = updatedTabs.find(tab => tab.id === tabId);
      taskStore.setActiveTab(newActiveTab);
      taskStore.setAllTabs(updatedTabs)
      emits("tab-activated", tabId);
    }
  }


  onMounted(() => {
    setTabWidth()
  });
  
  onUnmounted(() => {
      
  });

  watch(() => taskStore.rightPaneWidth, () => {
    setTabWidth()
  });

  watch(() => taskStore.codeEditorTabs, () => {
    //tabs.value = taskStore.getCodeEditorTabs
    setTabWidth()
  });

  //watch(() => taskStore.activeTab, () => {
    //console.log("watch: getActiveTab=> ", taskStore.getActiveTab)
    //activeTab.value = taskStore.getActiveTab
  //});

</script>
  
  <style scoped>
  .tabs {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .tabs li {
    text-align: left;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
    border-right: 1px solid #ccc;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .tab-active {
    background-color: #f5f5f5 ; /* Active tab background color */
  }
  
  .tab-inactive {
    background-color: #e0e0e0; /* Inactive tab background color */
  }

  .tabs li:hover .tab-close {
    display: block;
  }
  .tab-close{
    display: none;
  }
  .tab-close-icon{
    position: absolute;
    right: 5px;
    top: 8px;
    font-weight: bold;
  }
  .task-title{
    display: flex;
  }
  .unsave-icon{
    margin-top: 2px;
    position: absolute;
    padding-left: 4px;
  }
  .icon-color{
    background: blue;
  }
  </style>
  
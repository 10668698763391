<template>
  <!-- pages/tasks/[id].vue -->
  <div :class="{ ' ms-0 mr-0 pl-0 pr-0 stretch-vertical': true }" style="position: relative" @click="click_not_prevented_handler" ref="my_root_element_ref">
    <div class="ms-0 mr-0 position-relative">
      <div class="split-pane-container position-relative d-flex" v-if="! task_deleted_ref.valueOf()">
        <div class="pane left" :style="{width: `calc( ${(100 - parseInt(right_panel_width_ref.valueOf()) )}vw - ${left_nav_and_extra_ref.valueOf()})`}">
          <div :style="{}">
            <div class="task_edit_box">
              <TaskViewControls :task="task" :key="'view_controls_' + rerender_view_controls_ref.valueOf()"/>
              <div class="extra_left_margin">
                <LazyExpandableTask 
                  :parentTaskId="parentTaskId" 
                  :taskCache="taskCache" 
                  :item="task" class="me-2 mt-5" 
                  :expanded="true" 
                  :use_h1_for_title="true" 
                  :hide_expand_collapse_icon="false" prefix="" 
                  :index="-1" />
              </div>
            </div>
          </div>
        </div>
        <div class="pane right position-relative" :style="{width: parseInt(right_panel_width_ref.valueOf()) + 'vw', display: right_panel_width_ref.valueOf() == '0vw' ? 'none' : 'block'}">
          <div class="right-panel-docked" :style="{width: parseInt(right_panel_width_ref.valueOf()) + 'vw'}">
            <div class="gutter">
              <div :class="{ 'p-splitter-gutter-handle': true, 'fa-duotone': true, 'fa-circle-caret-right': gutter_direction_ref.valueOf() == 'right', 'fa-circle-caret-left': gutter_direction_ref.valueOf() == 'left' }"></div>
            </div>
            <LazyCommentPane v-if="(taskStore.getChatVisible) && ((userPreferenceStore.getUserPreference.chat) || (userPreferenceStore.getUserPreference['exec-history']))" :messages="task.messages" :task_id="task_id" :taskCache="taskCache"/>
            <CodeEditor 
              v-if="taskStore.getCodeEditorVisibility"
              :readonly="false"
              :taskCache="taskCache" 
              :is_new="false"
              :index="-1"
              :parentTaskId="parentTaskId" 
            />
          </div>
        </div>
      </div>
      <p v-if="task_deleted_ref.valueOf()" class="task_deleted_message_container">Task deleted.</p>
    </div>
    <teleport to="body" v-if="collab_modal_teleport_state.valueOf()">
      <LazyTaskCollaboratorModal />
    </teleport>
    <teleport to="body" v-if="submit_to_spaces_modal_teleport_state.valueOf()">
      <LazyTaskSubmitToSpacesModal />
    </teleport>

    <teleport to="body" v-if="task_deleting_state.valueOf() == route.params.id">
      <LazyTaskDeleteConfirmationModal 
        @task-delete-confirmation-modal-close="closeTaskDeleteConfirmationModal" 
        @deleted="task_deleted_handler"
        :item="task"
        :taskCache="taskCache"
        ref="task_delete_confirm_modal_ref"
      />
    </teleport>

    <div v-if="(is_copilot_page)" class="agent_page_space_holder_bottom">

    </div>
  </div>
</template>

<script setup>
import CodeEditor from '~/components/Task/CodeEditor.vue';

// We should only show the comment pane only after the main page has been mounted.
// Otherwise some initialization code that we put inside the onMounted hook inside /tasks/[id].vue will 
// overwrite initialization code that we put inside CommentPane.
let show_comment_pane_ref = ref(false); 

useHead({
  script: [
    {
      src: "/vstatic/javascript/skulpt/skulpt.js",
      type: "text/javascript",
      async: true,
    },
    {
      src: "/vstatic/javascript/skulpt/skulpt-stdlib.js",
      type: "text/javascript",
      async: true,
    },
    {
      src: "/vstatic/javascript/plotly-latest.min.js",
      type: "text/javascript",
      async: true,
    },
    {
      src: "/vstatic/javascript/jsonview.js",
      type: "text/javascript",
      async: true,
    },
    {
      src: "/vstatic/javascript/js-yaml/4.1.0/js-yaml.min.js",
      type: "text/javascript",
      async: true,
    }
  ]
});

const route = useRoute();
const task_id = computed(() => {
  return route.params.id;
});
await generateCSRFToken();
const taskStore = useTaskStore();
const settingStore = useSettingsStore();
const userPreferenceStore = useUserPreferenceStore();
const preferences = userPreferenceStore.getUserPreference;
let is_copilot_page = isCopilotPage()
if (is_copilot_page) {
  userPreferenceStore.getUserPreference.chat = false;
}

const userInfoStore = useUserInfoStore();
let user_info = userInfoStore.getUserInfo;

let settings = settingStore.getSetting;
if ((settings == null) || (Object.keys(settings).length == 0)) {
  await settingStore.setSetting(null);
}

let left_nav_and_extra_ref = ref('62px');
let right_panel_width_ref = ref('25vw');

const calculate_right_panel_width = (preferences) => {
  if ((preferences?.chat === false) && (preferences.hasOwnProperty('exec-history')) && (preferences['exec-history'] === false)) {
    right_panel_width_ref.value = '0vw'
  } else {
    right_panel_width_ref.value = '25vw';
  }
}
calculate_right_panel_width(preferences);

watch(userPreferenceStore.getUserPreference, async (newVal, oldVal) => {
  calculate_right_panel_width(newVal);
})

//const chat_visible = ref(true);

watch(() => taskStore.codeEditorVisible, (newVal, oldVal) => {
  if(taskStore.codeEditorVisible){
    taskStore.setChatVisible(false);
    right_panel_width_ref.value = '50vw'
  } else {
    calculate_right_panel_width(userPreferenceStore.getUserPreference)
  }
});

let job_id = route.query.job_id;
let iteration = route.query.iter;
const task_details_right_column_filter_state = useTaskDetailsRightColumnFilterState();
task_details_right_column_filter_state.value = 'comment'
if ((typeof(job_id) != 'undefined') && (job_id != '')) {
  task_details_right_column_filter_state.value = 'job';  
}

const query = computed(() => {
    let  query = ''
    if (route.query) {
        query = route.query
    }
    return query
})

onMounted(async() => { setupTaskPageWS(task_id.value, {}) });

let taskCache = {};

const initializeTaskDetailsPage = () => {
  window['dagknows'] = {}; 
  window['dagknows']['taskCache'] = {};
  taskCache = window['dagknows']['taskCache'];
  window['dagknows']['stopped_jobs'] = {};
  window['dagknows']['runbook'] = {};
}

if (typeof(window) != 'undefined') {
  initializeTaskDetailsPage();
}



const parentTaskId = "";

await taskStore.fetchTask(task_id.value, taskCache);
let task = taskStore.getTask;
const dayjs = useDayjs();
const now = dayjs();
const launchEditingMode = useLaunchEditingModeState();
const agent_message_state = useAgentMessageState();
launchEditingMode.value = "";
agent_message_state.value = "";
const bestProxyState = useBestProxyState();
let full_name = await getCurrentUserFullName();

if (is_copilot_page) { 

  let last_child_is_empty = isLastChildEmpty(task, taskCache)

  if (! last_child_is_empty) {
    // If we do not have an existing empty child task, then add a fake one.
    let empty_task = getEmptyTask();
    const now = dayjs();
    empty_task.title = `Temporary agent task ${now.format()} by ${full_name}`;
    empty_task.id = 'temp_task_id';
    empty_task.task = JSON.parse(JSON.stringify(empty_task)); // When using this empty_task as part of sub_tasks, we need this to be compatiable with what the backend is sending
    task.sub_tasks.push(empty_task);
  }
}

let description_seo = task.description;
if ((typeof(description_seo) != 'undefined') && (description_seo != null)) {
  description_seo = '';
} else {
  description_seo = removeHtml(description_seo, 'strict').trim();
}
if (description_seo == '') {
  description_seo = 'GenAI created and expert curated automation';
}

useSeoMeta({
  title: 'Dagknows - ' + task.title,
  ogTitle: 'Dagknows - ' + task.title,
  description: description_seo,
  ogDescription: description_seo,
  ogImage: 'https://www.dagknows.com/wp-content/uploads/2021/08/banner.svg',
  twitterCard: 'https://www.dagknows.com/wp-content/uploads/2021/08/banner.svg',
});

const collab_modal_teleport_state = useCollabModalTeleportState(); 

const show_right_side_ref = ref(false);
if (task.contributors && task.contributors.length > 1) {
  show_right_side_ref.value = true;
}
//show_right_side.value = true;

let is_top_level_editable = false;
if (process.client) {
  is_top_level_editable = canEdit(task)
}

let task_delete_confirm_modal_ref = ref(null)

if (process.client) {
  window["dk"] = {};
  window["dk"]["top_level_task_id"] = task["id"];
  window["dk"]["current_user"] = localStorage.getItem("uid");
  window["dk"]["is_top_level_editable"] = is_top_level_editable;
}

const show_hide_right_side = () => {
  show_right_side_ref.value = !show_right_side_ref.value;
};

const resizestart = (payload) => {
  setTimeout(function () {
    if (payload.sizes[1] < 25) {
      show_right_side_ref.value = true;
    }
  }, 1000);
};

const my_root_element_ref = ref(null)
const my_splitter_ref = ref(null);
const left_pane_ref = ref(null);
const right_pane_ref = ref(null);
let initial_direction = "left";
const right_pane_size_ref = ref(0.001);
const left_pane_size_ref = ref(100 - right_pane_size_ref.value);
let force_repaint_splitter = ref("splitter");
const initial_load_ref = ref(true);

const gutter_direction_ref = ref("right");

///colaborations modal
let collaborationModal = {};
let submitToSpacesModal = {};
let taskDeleteConfirmationModal = {};

const task_deleting_state = useTaskDeletingState();
const collab_modal_ready_state = useCollabModalReadyState();
const task_deleted_ref = ref(false)

onBeforeUnmount(() => {
  initializeTaskDetailsPage(); // Clean up and re-initialize for when the user go back to another task details page.
  hideTooltips(my_root_element_ref.value, true);
});

if ((typeof(window) != 'undefined') && (typeof ($) == 'undefined')) {
  // We are running in the browser, and the lightweight zepto library is not yet loaded, then load it.
  // We are depend on this library in quite a few places.
  await loadScript('/vstatic/zepto-1.2.0/zepto.min.js');      
}

if (is_copilot_page) {
  const proxy_aliases = extractProxyAliasesFromSettings(settings);
  let best_proxy = await selectBestProxyForCopilot(user_info.uid, proxy_aliases);
  bestProxyState.value = best_proxy;
}

onMounted(async () => {
  remove_leftover_tooltip();
  if (is_copilot_page) {
    document.querySelectorAll('.focus_textarea').forEach((elem) => {
      textarea_autogrow(elem)
    });

    let result = await getTaskLatestExecResult(task_id.value, []);
    result = result.exec_results;
    let items = Object.keys(result);
    let timeoutInterval = 0; 
    items.forEach((item) => {
      displayExecResultAgentPage(result[item], task, taskCache);
    })
  }

  show_comment_pane_ref.value = true;

  //initializeTooltips(my_root_element_ref.value);

  setTimeout(async function () {
    $('.fake_codemirror_style').remove();

    watchEffect(() => {
      openCollaborationModal();
    });
    watchEffect(() => {
      openTaskDeleteConfirmationModal();
    });
    watchEffect(() => {
      openSubmitToSpacesModal();
    });


    const right_panel_docked_elem = document.querySelector(".right-panel-docked");

    // This code was taken from https://codepen.io/pablowbk/pen/bGbxZoz
    const leftPane = document.querySelector(".left");
    const rightPane = document.querySelector(".right");
    const rightPane_jq = $(rightPane);
    right_panel_docked_elem.style.width = (rightPane_jq.width() - 10) + 'px';
    let windowWidth = $(document).width();
    const gutter = document.querySelector(".gutter");
    function resizer(e) {
      window.addEventListener("mousemove", mousemove);
      window.addEventListener("mouseup", mouseup);

      let prevX = e.x;
      const leftPanel = leftPane.getBoundingClientRect();

      function mousemove(e) {
        let newX = prevX - e.x;
        leftPane.style.width = leftPanel.width - newX + "px";
        rightPane.style.width = windowWidth - (leftPanel.width - newX - 2) + "px";
        right_panel_docked_elem.style.width = rightPane.style.width;
        let diff = windowWidth - parseInt(leftPane.style.width);

        if (diff < 20) {
          rightPane_jq.hide();
        } else {
          rightPane_jq.show();
        }

        taskStore.setRightPaneWidth(windowWidth - (leftPanel.width - newX - 2))
      }

      function mouseup() {
        window.removeEventListener("mousemove", mousemove);
        window.removeEventListener("mouseup", mouseup);
      }
    }

    gutter.addEventListener("mousedown", resizer);

    $("body").on( "click", ".p-splitter-gutter-handle", function() { toggle_display_of_right_pane(windowWidth, rightPane, right_panel_docked_elem,leftPane,gutter_direction_ref)});
    $("body").on( "dblclick", ".gutter", function() { toggle_display_of_right_pane(windowWidth, rightPane, right_panel_docked_elem,leftPane,gutter_direction_ref)});
    
    if ((typeof(job_id) != 'undefined') && (job_id != '')) {
      displayJob(job_id, iteration);
    }

    const button_click_state = useButtonClickState();
    if (button_click_state.value == 'suggest') {
      setTimeout(() => {
          document.querySelector('.fa-play').click();
          button_click_state.value = '';
      }, 2000)
    } else {
        button_click_state.value = '';
    }
  }, 10);
  setPageMountedState(true);
});

const toggle_display_of_right_pane = (windowWidth, rightPane, right_panel_docked_elem,leftPane,gutter_direction_ref) => {
  if ($(".p-splitter-gutter-handle").hasClass("fa-circle-caret-left")) {
    let rightPaneWidth = Math.floor(windowWidth * 0.25);
    let leftPaneWidth = Math.floor(windowWidth * 0.75);
    rightPane.style.width = rightPaneWidth + "px";
    right_panel_docked_elem.style.width = rightPane.style.width;
    leftPane.style.width = leftPaneWidth + "px";
    gutter_direction_ref.value = "right";
  } else {
    rightPane.style.width = 0 + "px";
    right_panel_docked_elem.style.width = rightPane.style.width;
    leftPane.style.width = windowWidth + "px";
    gutter_direction_ref.value = "left";
  }
}

watch(query, async (newVal, oldVal) => {
  const keys = ['job_id','iter','ts'];
  const change_detected = keys.some(key => getValue(newVal, key) !== getValue(oldVal, key));

  if (change_detected) {
    hideTooltips(my_root_element_ref.value);
    let job_id = route.query.job_id;
    let iteration = route.query.iter;
    await displayJob(job_id, iteration);
    hideWaitPage();
  }
});

let track_task_deleted_event_state = useTrackTaskDeletedEventState();

watch(track_task_deleted_event_state, async(newVal, oldVal) => {
  /*
  if (is_copilot_page) {
    let runbook_task_id = getTopRunbookTaskId();
    let all_step_numbers = [];
    let hidden_elements = document.querySelectorAll('.hidden_info');
    hidden_elements.forEach((elem) => {
      if (elem.id.includes('hidden_step_number_')) {
        let step_number = elem.innerText.trim();
        all_step_numbers.push(step_number);
      }
    });

    let result = await getTaskLatestExecResult(runbook_task_id, all_step_numbers);
    result = result.exec_results;
    let items = Object.keys(result);
    setTimeout(() => {
      items.forEach((item) => {
        displayExecResultAgentPage(result[item], task, taskCache);
      })
    }, 1000);
  }
  */

})

const openCollaborationModal = async () => {
  if ((taskStore.showCollaborationModal) && (collab_modal_teleport_state.value) && (collab_modal_ready_state.value)) {
    hideTooltips(my_root_element_ref.value);
    collaborationModal = new bootstrap.Modal(document.getElementById("collaboration-modal"), { backdrop: false });
    collaborationModal.show();
  }
};

const submit_to_spaces_modal_teleport_state = useSubmitToSpacesModalTeleportState();
const submit_to_spaces_modal_ready_state = useSubmitToSpacesModalReadyState();
const openSubmitToSpacesModal = async () => {
  if ((taskStore.showSubmitToSpaces) && (submit_to_spaces_modal_teleport_state.value) && (submit_to_spaces_modal_ready_state.value)) {
    hideTooltips(my_root_element_ref.value);
    submitToSpacesModal = new bootstrap.Modal(document.getElementById("submit-to-spaces-modal"), { backdrop: false });
    submitToSpacesModal.show();
  }
}


const openTaskDeleteConfirmationModal = async () => {
  if ((task_deleting_state.value == route.params.id) && (task_delete_confirm_modal_ref.value != null)) {
    hideTooltips(my_root_element_ref.value);
    let task = {};
    let task_id = route.params.id;
    //task = await readTask(route.params.id, taskCache)
    task = taskCache[task_id];

    task_delete_confirm_modal_ref.value.setSubtasksCount(task.sub_tasks.length);
    taskDeleteConfirmationModal = new bootstrap.Modal(document.getElementById("task-delete-confirmation-modal"), { backdrop: false });
    taskDeleteConfirmationModal.show();
  }
};

const closeTaskDeleteConfirmationModal = async () => {
  task_deleting_state.value = '';
  hideTooltips(my_root_element_ref.value);
  nextTick(() => {
    taskDeleteConfirmationModal.hide()
  })
};

const task_deleted_handler = () => {
  task_deleted_ref.value = true;
  task_deleting_state.value = '';
}

let rerender_view_controls_ref = ref(0);
let space_dropdown_state = useSpaceDropdownState();
watch(space_dropdown_state, async (newVal, oldVal) => {
  rerender_view_controls_ref.value = rerender_view_controls_ref.value + 1;
});
</script>

<style scoped>
.fa-circle-user,
.fa-comments {
  font-size: 30px;
  margin-left: 5px;
}

.fa-comments {
  cursor: pointer;
  margin-left: 10px;
}
h1 {
  background-color: #f3f3f3;
}

.task_edit_box {
  min-width: 600px;
  /* margin: 20px 22px 20px 0px; */

  /*width: fit-content;*/
  width: calc(100% - 22px);
}

.width-75 {
  width: 75%;
}
.width-25 {
  width: 25%;
}

.show_hide_right_side_icons_container {
  position: absolute;
  z-index: 20;
  /*right: 20px;*/
  bottom: 20px;
}
.show_hide_right_side_icons_container i {
  cursor: pointer;
  font-size: 20px;
}
.left_panel {
  overflow-x: auto;
}
.right-panel-docked {
  height: calc(100vh - 48px);
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 999;
}
.task_deleted_message_container {
  margin-top: 20px;
  text-align: center;
}

.extra_left_margin {
  margin-left: 10px;
}
.extra_left_margin .create_edit_view_task_buttons_container{
 margin-top: 20px !important;
}
</style>

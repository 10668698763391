export const displayInputOutput = (root_task_id, index_path, results) => {
    try {
        let my_input_arr_ref = window['dagknows']['runbook'][`${root_task_id}_${index_path}`]['input'];
        let my_inputs_dic = {};
        if (results.hasOwnProperty('var_values')) {
          my_inputs_dic = results['var_values'];
        }
        my_input_arr_ref.value.forEach((input_param) => {
          let param_name = input_param['name'];
          let param_value = my_inputs_dic[param_name];
          input_param['value'] = param_value;
        });

        let my_output_arr_ref = window['dagknows']['runbook'][`${root_task_id}_${index_path}`]['output'];
        let my_outputs_dic = {};
        if (results.hasOwnProperty('var_values')) {
          my_outputs_dic = results['var_values'];
        }
        my_output_arr_ref.value.forEach((output_param) => {
          let param_name = output_param['name'];
          let param_value = my_outputs_dic[param_name];
          output_param['value'] = param_value;
        });
      } catch (ex) {
        // Ignore for now
      }

}
export const useProxyRolesForUserStore = defineStore("proxyRolesForUserStore", {
    state: () => ({
        proxyRoles: null,
    }),
    getters: {
        getProxyRoles() {
            return this.proxyRoles;
        },
    },
    actions: {
        setProxyRoles(info) {
            this.proxyRoles = info
        },
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useProxyRolesForUserStore, import.meta.hot));
}
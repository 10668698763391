export const normalizedIndexPath = (index_path_to_start, index_path_from_start) => {
  let modif_ip_from_start = index_path_from_start; // _ip_ abbreviate for index path
  let index_path = "";
  if (index_path_to_start != "") {
    index_path += index_path_to_start;
    if (modif_ip_from_start.startsWith("0.")) {
      modif_ip_from_start = modif_ip_from_start.substring(2).trim();
    } else if (modif_ip_from_start.startsWith("0")) {
      modif_ip_from_start = modif_ip_from_start.substring(2).trim();
    }
  }

  if (modif_ip_from_start != "") {
    if (index_path.length > 0) index_path += ".";
    index_path += modif_ip_from_start;
  }
  return index_path
}

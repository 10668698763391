export const formatTimeForDom = (ts, dayjs, element) => {

    let dobj = '';
    let formatted = '';

    if (typeof(ts) == 'undefined') {
      formatted = 'TBD';
    } else if ((ts + '').search(/[^\d\.]/) > -1) { // If the timestamp includes any characters that is not a digit or a dot.
        dobj = dayjs(ts).local();
        formatted = dobj.format("YYYY-MM-DD hh:mm:ss a");
    } else if ((ts != '') && (ts > -1)) {
        dobj = dayjs.unix(ts).local();
        formatted = dobj.format("YYYY-MM-DD hh:mm:ss a");
    } else {
        formatted = 'TBD'
    }

    if ((element) && (element != null)) {
        element.innerHTML = formatted;
    }
}

export const getTaskLatestExecResult = async (runbook_task_id, step_numbers) => {
    let all_index_paths = [];
    step_numbers = step_numbers || [];
    let index_paths_str = '';
    if (step_numbers.length > 0) {
        step_numbers.forEach((step_number) => {
            let index_paths_arr = getAllIndexPathFromStepNumber(step_number);
            index_paths_arr.forEach((index_path) => {
                if (! all_index_paths.includes(index_path)) {
                    all_index_paths.push(index_path);
                }
            })
        });
        index_paths_str = all_index_paths.join(',');    
    } else {
        index_paths_str = "all"
    }

    const result = await logFetchAJAX(getUrl(`/api/tasks/${runbook_task_id}/jobs/results?child_paths=${index_paths_str}`), {
        method: 'GET',
        params: {
        }
    });
    return result;
}
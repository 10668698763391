export const hidePreviousExecutionResult = () => {
    $(".left_side_execution_result").empty().parent().removeClass('left_side_execution_result_with_padding_and_border');
    $('.left_side_execution_result_warning').removeClass('left_side_execution_result_warning');
    $('.left_side_execution_result_error').removeClass('left_side_execution_result_error');
    $('.left_side_execution_result_success').removeClass('left_side_execution_result_success');
    $(".class_execution_results_box").css('height', 'auto');
    $(".class_execution_results_box").css('max-height', '');
    $(".class_execution_result_expand_arrow").find('i').removeClass('fa-caret-up').addClass("fa-caret-down").parent().hide();
    $('.execution_result_plot').empty();
    //$('.selected_job').removeClass('selected_job');
    $('.executing_task').removeClass('executing_task');
}

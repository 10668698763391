<template>
    <div class="view_controls_container" v-if="isReady"> 
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="editor_title" name="title" v-model="taskSections.title" @change="onViewControlChange()">
        <label class="form-check-label" for="editor_title">title</label>
      </div>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="editor_des" name="description" v-model="taskSections.description" @change="onViewControlChange()">
        <label class="form-check-label" for="editor_des">description</label>
      </div>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="editor_code" ame="code" v-model="taskSections.code" @change="onViewControlChange()">
        <label class="form-check-label" for="editor_code">code</label>
      </div>
      
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="editor_io" name="io" v-model="taskSections.tag" @change="onViewControlChange()">
        <label class="form-check-label" for="editor_io">tag</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="editor_io" name="io" v-model="taskSections.io" @change="onViewControlChange()">
        <label class="form-check-label" for="editor_io">I/O</label>
      </div>

    </div>
  </template>
  
  <script setup>
  
  const taskStore = useTaskStore();
  const taskSections = ref({})
  const isReady = ref(false)

  const allTabs = computed( () => {
    return taskStore.getCodeEditorTabs;
  })
  
  const onViewControlChange = () => {
    let updatedTabs = allTabs.value.map(tab => {
      return {
        ...tab,
        visible_section: tab.is_active ? taskSections.value : tab.visible_section
      };
    });

    taskStore.setAllTabs(updatedTabs)
  };

  onMounted( () => {
    
    const sections = taskStore.getActiveTab
    //console.log("onMounted=> taskSections:: ", sections.visible_section)
    taskSections.value = sections.visible_section
    isReady.value = true
  })
  </script>

class WSConn {
  ws /* : null | WebSocket */ = null
  intervalId = null
  wsopened = false
  subscriptionInfo = {}
  onMessage = null
  onError = null
  onClose = null
  connId = null
  pingCounter = 0

  constructor(url) {
    this.pingCounter = 0
    this.url = normalizeWsUrl(url)
  }

  subscribe(eventName, filters, handler) {
    if (!this.subscriptionInfo[eventName]) {
      this.subscriptionInfo[eventName] = []
    }
    this.subscriptionInfo[eventName].push({
      subscription: {
        filters: filters,
      },
      handler: handler,
    })
    this.sendSubscriptions()
  }

  sendSubscriptions() {
    if (this.wsopened) {
      const subinfo = {}
      Object.keys(this.subscriptionInfo).forEach(evtname => {
        subinfo[evtname] = []
        const values = this.subscriptionInfo[evtname]
        values.forEach(sub => {
          if (sub.subscription) {
            subinfo[evtname].push(sub.subscription)
          }
        })
      })
      console.log("Sending Sub Info: ", subinfo)
      this.ws.send(JSON.stringify({"type": "setsubs", "subscriptions": subinfo}))
    }
  }

  start() {
    if (this.ws != null) {
      console.log("Websocket connection already established to url: ", this.url)
      return
    }
    this.ws = new WebSocket(this.url);
    this.pingCounter = 0;
    const ws = this.ws
    ws.onopen = (event) => {
      console.log("WS Opened: ", event);
      this.wsopened = true;
      this.sendSubscriptions()
    }

    ws.onerror = (event) => {
      if (this.onError) this.onError(event)
      else {
        console.log("WS Error: ", event);
      }
    };
    ws.onclose = (event) => {
      this.wsopened = false;
      this.ws = null
      this.connId = ""
      console.log("WS Closed: ", event);
      if (this.intervalId != null) {
        clearInterval(this.intervalId);
      }
      const retry = true
      if (this.onClose) retry = this.onClose(event)
      if (retry) {
        console.log("Socket closed.  Reconnecting in 5 seconds")
        setTimeout(() => {this.start()}, 5000)
      }
    };
    ws.onmessage = (event) => {
      const m = JSON.parse(event.data);
      const msgs = Array.isArray(m) ? m : [m]
      for (var i = 0;i < msgs.length;i++) {
        const msg = msgs[i]
        if (msg.type == "ping") {
          // console.log("Got Ping: ", msg)
          if (this.connId == "" || this.connId == null) {
            this.connId = msg.connId
          }
        } else {
          console.log("Received Message: ", msg)
        }
        if (this.onMessage) this.onMessage(msg)
        else {
          if (msg.Name || null != null) {
            const eventHandlers = this.subscriptionInfo[msg.Name] || null
            console.log("Found handlers: ", msg.Name, eventHandlers, this.subscriptionInfo)
            if (eventHandlers != null) {
              eventHandlers.forEach(handler => { handler.handler(msg) })
            }
          }
          // TODO - See eventName, eventId, sources and see which handlers should get it
        }
      }
    };

    this.intervalId = setInterval(() => {
      try {
        ws.send(JSON.stringify({ type: "ping", "connId": this.connId, pingId: this.pingCounter++ }));
      } catch(ex) {
        // Uncaught InvalidStateError: Failed to execute 'send' on 'WebSocket': Still in CONNECTING state
        // Ignore this exception (don't send error email for this) because this is a ping that happens 
        // frequently.  Don't want to flutter my email inbox with this error message.
      }
    }, 10000);
    return ws;
  }
}

export const newWSConn = (url) => {
  return new WSConn(url)
}

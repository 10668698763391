export const cacheLastOutput = (task_id, output_type, html) => {
    /*
    Currently, we have two different types of output: "regular" and "plot".  We store them inside two different DIVs.
    They are styled differently.  Blocks are rendered inside the "plots_" DIV.
    */
    
    if (! window.dagknows.hasOwnProperty('cached_output')) {
        window.dagknows.cached_output = {};
    }

    if (! window.dagknows.cached_output.hasOwnProperty(task_id)) {
        window.dagknows.cached_output[task_id] = {};
    }


    window.dagknows.cached_output[task_id][output_type] = html;
}
export const shouldUpdate = (job_id, iteration) => {
    /*
    When looking at the task details page, and receiving event for another job for the same task but I did not trigger 
    that job, should I be able to see live update?
    */
   
    let execution_params_visible_state = useExecutionParamsVisibleState();
    if (execution_params_visible_state.value) {
      return false;
    }

    let update = true;

    if (window['dagknows'].hasOwnProperty('active_job_id')) {
      if (window['dagknows']['active_job_id'] != job_id) {
        update = false;
      } else {
        if (window['dagknows'].hasOwnProperty('active_job_iteration')) {
          if (window['dagknows']['active_job_iteration'] != iteration) {
            update = false;
          }
        }
      }
    }
    return update;
}
export const track_iterations = (msg, message_type) => {
    if (typeof(window) != 'undefined') {
      if (! msg.hasOwnProperty('job_id')) {
        return;
      }
      if ((! msg.hasOwnProperty('job')) || (! msg['job'].hasOwnProperty('status'))) {
        return;
      }
  
      let job_id = msg['job_id'];
  
      // On the job cards that are displayed in the right panel, ideally, when the user selects an iteration, 
      // we should display the start and finish time for that iteration, but when I call the getJobs composable, 
      // passing it the job_id and the iteration, the backend does not give me the start and finish time for that 
      // iteration, causing me having to resolve to this hack, and track the start and finish time on the frontend
      if (! window['dagknows'].hasOwnProperty('job_iteration_info')) {
        window['dagknows']['job_iteration_info'] = {};
      }
  
      if (! window['dagknows']['task_details_job_iteration_ref'].hasOwnProperty(job_id)) {
        window['dagknows']['task_details_job_iteration_ref'][job_id] = ref([]);
      }
  
  
      if (['job_started','job_finished'].includes(message_type)) {
        let iter = msg.iter;
        let job_id_iter = `${job_id}_${iter}`;
        if (! window['dagknows']['job_iteration_info'].hasOwnProperty(job_id_iter)) {
          window['dagknows']['job_iteration_info'][job_id_iter] = {};
        }
        if (message_type == 'job_started') {
          window['dagknows']['job_iteration_info'][job_id_iter]['started_at'] = msg['started_at'];
        } else {
          window['dagknows']['job_iteration_info'][job_id_iter]['finished_at'] = msg['finished_at'];
        }
  
        // Update the array that track the number of iterations
        if (message_type == 'job_started') {
          let curr_iter = msg.iter;
          let array_ref = window['dagknows']['task_details_job_iteration_ref'][job_id];
          if (! array_ref.value.includes(curr_iter)) {
            let arr = [];
            let i = 0;
            let lower_limit = Math.max(0, curr_iter - 500);
            for(i = curr_iter; i >= lower_limit; i--) {
              arr.push(i);
            }
            array_ref.value = arr;
          }
        } 
      } else if (message_type == 'job_info') {
  
        if (msg['job'].hasOwnProperty('iter_statuses')) {
          let iter_statuses = msg['job']['iter_statuses'];
          let latest_iter = 0;
          for (let iter in iter_statuses) {
            let iter_status = iter_statuses[iter];
            let job_id_iter = `${job_id}_${iter}`;
            if (! window['dagknows']['job_iteration_info'].hasOwnProperty(job_id_iter)) {
              window['dagknows']['job_iteration_info'][job_id_iter] = {};
            }
            window['dagknows']['job_iteration_info'][job_id_iter]['started_at'] = iter_status['started_at'];
            window['dagknows']['job_iteration_info'][job_id_iter]['finished_at'] = iter_status['finished_at'];
            window['dagknows']['job_iteration_info'][job_id_iter]['updated_at'] = iter_status['updated_at'];
            latest_iter = Math.max(latest_iter, iter);
          }
  
          // Update the array that track the number of iterations
          let array_ref = window['dagknows']['task_details_job_iteration_ref'][job_id];
          if (! array_ref.value.includes(latest_iter)) {
            let arr = [];
            let i = 0;
            let lower_limit = Math.max(0, latest_iter - 500);
            for(i = latest_iter; i >= lower_limit; i--) {
              arr.push(i);
            }
            array_ref.value = arr;
          }
        } else {
          if (msg['job']['status'].hasOwnProperty('curr_iter')) {
            let array_ref = window['dagknows']['task_details_job_iteration_ref'][job_id];
            let latest_iter = msg['job']['status']['curr_iter'] - 1;
            if (! array_ref.value.includes(latest_iter)) {
              let arr = [];
              let i = 0;
              let lower_limit = Math.max(0, latest_iter - 500);
              for(i = latest_iter; i >= lower_limit; i--) {
                arr.push(i);
              }
              array_ref.value = arr;
            }
  
          }
        }
      }
    }
  }
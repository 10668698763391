export const getAllIndexPathFromStepNumber = (step_number) => {
    /*
    Parent:
    1: https://localhost/tasks/vKjuadPkKAG1d2XQLAzU?path=0.0
  1.1: https://localhost/tasks/r0BljXRWZUwhIXvUyopB?path=0.0.0
1.1.1: https://localhost/tasks/Ld0IVLAkmkNLjuCMZZqy?path=0.0.0.0
1.1.1.1: https://localhost/tasks/L89wUsxDk9fhr9XLYkYA?path=0.0.0.0.0

My step number -> my index path: split, subtract everything by 1, unshift(0), and then join, or just call buildStartingChildPath
My step number -> my parent's step number: split, pop, join
*/
    let index_paths = []
    if (step_number != "") {
        // Figure out my_index_path
        let my_index_path = buildStartingChildPath(step_number)

        // Figure out the my parent's step_number
        let parent_step_number = '';
        let parts = step_number.split(/\./);
        parts.pop();
        parent_step_number = parts.join('.');

        // Get the index_paths for the parents
        index_paths = getAllIndexPathFromStepNumber(parent_step_number);

        // Add my index_path to the list
        index_paths[index_paths.length] = my_index_path;
    } else {
        index_paths.push(0);
    }
    return index_paths;
}
export const isSingleIterationJob =  (type, obj) => {
    // Detecting whether this is a single iteration job or a multi-iteration job can be tricky.
    // The logic that we use here: if the information inside schedule_options match with the default value,
    // then it is a single iteration job.

    let is_single_iteration_job = false;
    let schedule_options = {};

    if (typeof(obj['job']['special_param_values']) == 'undefined') {
      return true;
    }
    if (type == 'message') {
        schedule_options = obj['job']['special_param_values']['schedule_options'];
    } else {
        schedule_options = obj['job']['special_param_values']['schedule_options'];
    }

    if ((typeof(schedule_options) != 'undefined') && (schedule_options != null)) {
        if (
            ((schedule_options.end == "") || (schedule_options.end == null)) && 
            ((schedule_options.end_option == "forever") || (schedule_options.end_option == '')) &&
            ((schedule_options.num_iterations == "") || (schedule_options.num_iterations == null)) &&
            ((schedule_options.repeat_interval == "") || (schedule_options.repeat_interval == null)) &&
            ((schedule_options.repeat_interval_type == "hours") || (schedule_options.repeat_interval_type == ''))
        ) {
            // Match with the default values
            is_single_iteration_job = true;
        }    
    } else {
        // schedule_options is either undefined or null 
        // Look like this is a one-off jobs (single iteration job)
        is_single_iteration_job = true
    }


    return is_single_iteration_job;

}

export const renderBlocks = (blocks, containing_element) => {
    if (blocks.length > 0) {
        for (let i = 0; i < blocks.length; i++) {
          let block = blocks[i];
          let result_string = '';
          if (typeof(block.type) != 'undefined') {
            let block_type = block.type.toLowerCase();
            if (block_type == 'div') {
              result_string = `<div><div class='block_title'>${removeHtml(block.title,'strict')}</div><div class='block_content'>${ removeHtml(block.content, 'strict')}</div></div>`;
            } else if (block_type == 'table') {
              result_string = `<table class='block_table table table-striped table-hover table-borderless table-sm'><caption>${removeHtml(block.title,'strict')}</caption><tbody>`;
              for (let row_index = 0; row_index < block.num_rows; row_index++) {

                result_string = result_string + '<tr>';

                for (let col_index = 0; col_index < block.num_cols; col_index++) {
                  let cell_value = '';
                  if (typeof(block.cell_values) != 'undefined') {
                    if (block.cell_values.hasOwnProperty(row_index)) {
                      cell_value = block.cell_values[row_index][col_index] || '';
                    } else {
                      cell_value = '';
                    }
                  }
                  let start_cell_tag = "<td>";
                  let close_cell_tag = "</td>";
                  if (((row_index == 0) && (block.has_header_row)) || ((col_index == 0) && (block.has_header_col))) {
                    start_cell_tag = "<th>";
                    close_cell_tag = "</th>";
                  }
                  
                  result_string = result_string + start_cell_tag + removeHtml(cell_value) + close_cell_tag;
                }
                result_string = result_string + '</tr>';
              }
              result_string = result_string + '</tbody></table>';
            } else if (block_type == 'plot') {
              const plot = block;
              for (var j = 0 ; j < plot.traces.length;j++) {
                plot.traces[j]["type"] = plot.traces[j]["type"] || "lines"
              }
              plotGraphs(plot.title, plot.xlabel, plot.ylabel, plot.traces, containing_element);
            }
          }

          // Done with constructing the result_string for this block.
          // Append it to the div
          $(result_string).appendTo(containing_element);
        }
      }
}

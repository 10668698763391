export const isLastChildEmpty = (task, taskCache) => {
    let last_child_is_empty = false;
    let sub_tasks_count = task.sub_tasks.length;
    if (sub_tasks_count > 0) {
      let last_child_task = task.sub_tasks[sub_tasks_count - 1];
      if (last_child_task.task.title.toLowerCase().includes('temporary agent task')) {
        last_child_is_empty = true;
      } else {
        // If the last child is not empty, then look at the last child of the last child.
        let last_child_task_id = last_child_task.task.id;
        last_child_task = taskCache[last_child_task_id]
        last_child_is_empty = isLastChildEmpty(last_child_task, taskCache)
      }
    } else {
        // If the task does not have any sub_tasks, look at the task itself
        if (task.title.toLowerCase().includes('temporary agent task')) {
            last_child_is_empty = false;
        }
    }
    return last_child_is_empty;
}
export const normalizeWsUrl = (url) => {
  let isRelative = true;
  ["http", "ws", "https", "wss"].forEach(scheme => {
    if (url.startsWith(scheme + "://")) {
      isRelative = false;
    }
  })
  if (isRelative) {
    // then prepend the browser address first
    if (url.startsWith("/")) {
      url = url.substring(1)
    }
    url = `${window.location.protocol}//${window.location.host}/${url}`
  }
  return url.replace("https://", "wss://").replace("http://", "ws://")
}

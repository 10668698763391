export const getMessageType = (message) => {
    let message_type = "";
    if (message.hasOwnProperty("job_id")) {
      if (message.hasOwnProperty("exec_results")) {
        message_type = "job";
      } else if (message.req == "taskExecuted") {
        // store_task_exec_results(message);
        message_type = "task_exec_result";
      } else if (message.req == "taskStarted") {
        // store_task_exec_results(message);
        message_type = "task_started";
      } else if (message.req == "jobStarted") {
        // store_job_exec_results(message);
        message_type = "job_started";
      } else if (message.req == "jobFinished") {
        // store_job_exec_results(message);
        message_type = "job_finished";
      } else if (message.req == 'job_info') {
        message_type = 'job_info';        
      }
      // 
    } else if ((message.hasOwnProperty('msg')) && (message.hasOwnProperty('search_chat_mode')) && (message['search_chat_mode'] == 'chat')) {
        message_type = "comment";
    }
    return message_type;
};

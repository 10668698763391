export const displayJob = async (job_id, iteration) => {
    hidePreviousExecutionResult();
    if ((typeof(job_id) != 'undefined') && (job_id != '')) {
      window['dagknows']['active_job_id'] = job_id;
    } else {
      delete window['dagknows']['active_job_id'];
    }
  
    if ((typeof(iteration) != 'undefined') && (iteration != '')) {
      window['dagknows']['active_job_iteration'] = iteration;
    } else {
      delete window['dagknows']['active_job_iteration'];
    }
  
    if ((typeof(job_id) == 'undefined') || (job_id == '')) {
      return;
    }
  
    // Handle updating the output
    let root_task_id = window.location.pathname.replace(/\/tasks\//, '');
    const job = await getJob(job_id, iteration)
    const ters = job.task_exec_results;
    const index_path_to_start = (job.job.starting_child_path || "").trim();
    for (const terkey in ters) {
      const ter = ters[terkey]
      if (ter.index_path) {
        let index_path_from_start = ter.index_path.trim();
        const index_path = normalizedIndexPath(index_path_to_start, index_path_from_start)
        updateTaskExecResultsView(root_task_id, index_path, ter, job_id, iteration)
      }
    }
  
    // Handle update the input and output params
    //populateInputOutputParamsFromJob(job);
  
    setTimeout(() => {
      // Look for the corresponding job div, and add the selected_job class to it to highlight 
      // the corresponding job div.
      //$('.selected_job').removeClass('selected_job');
      let div_id = 'job_comment_pane_' + job_id;
      let div = document.querySelector('#' + div_id);
      if (div != null) {
        //findAncestorWithClass(div, 'message_job').classList.add('selected_job');
      }
  
      // Update the iterations_select_dropdown element to show the corresponding iteration
      // On the task details page, for performance reason, the panel on the right hand side is 
      // load and displayed later, so here we have to use a setTimeout
      const dayjs = useDayjs();
      updateJobDivV2(job_id, iteration, job['job']['status'], dayjs,job);
  
    }, 1000);
}

export const isChildOf = (child_task_id, parent_task, taskCache) => {
    /*
    Here we use the sub_tasks array which is not perfect.
    The task object used to contain the 'descendants' field which contains all the descendants, but I am not 
    seeing this field any more.  Need to search the code base for 'descendants', see why it is no longer sending 
    this.  Could be for performance reason.  Need to see how we can send this, or determine a better way to determine 
    if a given child_task_id is a descendant of parent_task, perhaps by looking into the taskCache object.
    */
    let is_child = false;

    /*
    let len = parent_task.sub_tasks.length;
    for (let i = 0; i < len; i++) {
        let sub_task = parent_task.sub_tasks[i];
        if (child_task_id == sub_task.task.id) {
            is_child = true;
            break;
        }
    }
    */

    document.querySelectorAll('.hidden_task_id').forEach((elem) => {
        if (elem.innerText.trim() == child_task_id) {
            is_child = true;
        }
    })
    return is_child;
} 